import { Provider, atom, createStore, useAtom } from "jotai";
import type { PropsWithChildren, ReactNode } from "react";
import type { AlertType } from "../Alert";

export const snackbarStore = createStore();

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  return <Provider store={snackbarStore}>{children}</Provider>;
};

export const snackbarAtom = atom<{
  open: boolean;
  message: ReactNode;
  autoHideDuration: number;
  severity: "success" | "warning" | "error";
}>({
  open: false,
  message: "",
  autoHideDuration: 6000,
  severity: "success",
});

export function snackbarSuccess(message: ReactNode) {
  const snackbar = snackbarStore.get(snackbarAtom);

  snackbarStore.set(snackbarAtom, {
    ...snackbar,
    open: true,
    message,
    severity: "success",
  });
}

export function snackbarWarn(message: ReactNode) {
  const snackbar = snackbarStore.get(snackbarAtom);

  snackbarStore.set(snackbarAtom, {
    ...snackbar,
    open: true,
    message,
    severity: "warning",
  });
}

export function snackbarError(message: ReactNode) {
  const snackbar = snackbarStore.get(snackbarAtom);

  snackbarStore.set(snackbarAtom, {
    ...snackbar,
    open: true,
    message,
    severity: "error",
  });
}

export const useSnackbar = () => {
  const [snackbar, setSnackbar] = useAtom(snackbarAtom);

  const close = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return {
    success: snackbarSuccess,
    warn: snackbarWarn,
    error: snackbarError,
    close,
    isOpen: snackbar.open,
    message: snackbar.message,
    severity: snackbar.severity as AlertType,
    autoHideDuration: snackbar.autoHideDuration,
  };
};
